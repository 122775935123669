<template>
  <VForm
    ref="form"
    v-model="isValid"
    lazy-validation
  >
    <VRow>
      <VCol>
        <TTTextField
          v-model="currentProvider.title"
          :label="$t('content-provider.name')"
          :rules="[rules.required]"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTTextField
          v-model="currentProvider.siteLink"
          :label="$t('content-provider.href')"
          :rules="[rules.url]"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTTextarea
          v-model="currentProvider.description"
          :label="$t('content-provider.description')"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VImg
          width="100px"
          height="auto"
          :src="providerLogoUrl"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VFileInput
          v-model="currentProvider.logo"
          :hint="$t('content-provider.save_file_comment')"
          accept="image/png, image/jpeg"
          :rules="[rules.image]"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VRow>
          <TTBtn
            :loading="loading"
            :disabled="!isValid || loading"
            @click="$emit('submit', $refs.form.validate(), currentProvider)"
          >
            {{ submitButtonText }}
          </TTBtn>

          <TTBtn
            v-if="isVisibleCancelButton"
            class="ml-2"
            @click="$emit('cancel')"
          >
            {{ $t('content-provider.cancel') }}
          </TTBtn>
        </VRow>
      </VCol>
    </VRow>
  </VForm>
</template>
<script>
import { required } from '@front.backoffice/common';
import url from '@front.backoffice/common/src/rules/url';
import fileType from '@front.backoffice/common/src/rules/fileType';
import getImageUrl from '@front.backoffice/common/src/getGoghaImageUrl';

export default {
  name: 'ContentProviderForm',
  inject: ['Names'],
  props: {
    provider: {
      type: Object,
      default: () => ({
        title: '',
        siteLink: '',
        description: '',
        logo: '',
      }),
    },
    submitButtonText: {
      type: String,
      default: 'Создать',
    },
    isVisibleCancelButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      loading: false,
      currentProvider: {},
    };
  },
  computed: {
    rules() {
      return {
        required: required(),
        url: url(),
        image: fileType(['jpeg', 'jpg', 'png']),
      };
    },
    providerLogoUrl() {
      if (this.currentProvider.logo) {
        return URL.createObjectURL(this.currentProvider.logo);
      }
      return getImageUrl({
        uuid: this.currentProvider.id,
        type: 'provider',
        name: 'icon',
      });
    },
  },
  watch: {
    provider: {
      deep: true,
      handler(value) {
        this.currentProvider = value;
      },
    },
    currentProvider: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
